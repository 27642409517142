import React from 'react';

const BenefitsSection = () => {
  return (
    <section id="Benefits">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/benefitsHero_dsk.jpg"
                alt="Member benefits include the WoodmenLife Focus Forward Scholarship&reg;"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/benefitsHero_mbl.jpg"
                alt="Member benefits include the WoodmenLife Focus Forward Scholarship&reg;"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 style={{ margin: '0 0 0 0' }} className="navy size24"><b>Your Clients Receive</b></h2>
            <h1 style={{ margin: '0 0 30px 0' }} className="Oswald navy">Exclusive Benefits From WoodmenLife</h1>
            <p>
              When customers purchase a WoodmenLife product, they become WoodmenLife members and receive access to exclusive member benefits<span className="size12"><sup>5</sup></span>. From free access to self-service legal forms to financial assistance following a natural disaster, these member benefits can help right away. Plus, there’s no extra cost nor obligation to use them.
            </p>
            <p>
              These exclusive benefits include:
            </p>

            <ul style={{ listStylePosition: 'outside', listStyleType: 'square', margin: '0px' }}>
              <li style={{ padding: '0 0 10px 0' }}>  
                <b>WoodmenLife Focus Forward Scholarship</b><span className="size12"><sup>&reg;</sup></span> <b>&mdash;</b> Since 2017, WoodmenLife has awarded scholarships<span className="size12"><sup>5</sup></span> worth $2.7 million to high school graduates pursuing higher education
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                <b>Student Loan Relief &mdash;</b> Every year, WoodmenLife gives up to $200,000 in awards<span className="size12"><sup>5</sup></span> to eligible members to help pay off their student loans
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                <b>LawAssure</b><span className="size12"><sup>&trade;</sup></span> <b>&mdash;</b> With free, easy-to-use online templates<span className="size12"><sup>6</sup></span>, members can create customized wills, powers of attorney and healthcare directives
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                  <b>Natural Disaster Benefit &mdash;</b> Qualified members whose homes are damaged or destroyed by natural disasters may be eligible for up to $1,000<span className="size12"><sup>5</sup></span> to help with home repair costs
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                <b>Family Legacy &mdash;</b> Should a member&#8217;s children be orphaned, WoodmenLife will provide each child $1,000<span className="size12"><sup>5</sup></span> a month to pay for their care, until they reach the age of 19, or age 22 if enrolled in school
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                <b>Life&#8217;s Perks<span className="size12"><sup>&reg;</sup></span> &mdash;</b> Our member discount program offers everyday savings<span className="size12"><sup>7</sup></span> at more than 30,000 local and national retailers
              </li>
              <li style={{ padding: '0 0 10px 0' }}>
                <b>First Responders Benefit &mdash;</b> WoodmenLife pays $25,000<span className="size12"><sup>8</sup></span> to the family of every First Responder member who dies in the line of duty (First Responders include law enforcement, firefighters, EMTs, and support personnel)
              </li>
            </ul>  

          </div>
        </div>
      </div>

      
              

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '30px' }} className="size10">
            5. WoodmenLife member benefits are available to members. An individual becomes a member by joining our shared commitment to family, community and country, and by purchasing a WoodmenLife product. These benefits are not contractual, are subject to change and have specific eligibility requirements, such as length of membership, number of qualifying members in household and/or qualifying event.<br/>
            6. WoodmenLife has entered into a sponsored marketing relationship with Epoq, Inc. (Epoq) to offer document preparation services to WoodmenLife members. Epoq is an independent service provider. WoodmenLife is not affiliated with Epoq and does not administer these document preparation services. WoodmenLife does not provide, is not responsible for, does not assume liability for, and does not guarantee the accuracy, adequacy or results of any service or documents provided by Epoq. WoodmenLife, its employees and representatives are not authorized to give legal advice. Not all services are available in all states.<br/>
            7. WoodmenLife has entered into sponsored marketing relationships with companies that agree to offer discounts to WoodmenLife members. WoodmenLife is not affiliated with these companies and does not administer these discounts for products or services.<br/>
            8. $25,000 benefit applies to officially registered non-military First Responders. WoodmenLife Extras are not contractual, are subject to change and have specific eligibility requirements.
            </div>

          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
          
            <div className="size14" style={{ margin: '40px 0 0 0' }}>
              <b>In Partnership, We Succeed</b>
            </div>
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Home"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default BenefitsSection;
